import React, { useEffect } from 'react';
import { useMatomo } from '@m4tt72/matomo-tracker-react'


export default function Imprint() {

    const {trackPageView} = useMatomo();
    useEffect( () => {
        document.title = 'Impressum';
        trackPageView();
    });

    return (




        <div style={{ padding: '20px' }} id="imprint">
            <h2>Impressum</h2>
            (zugleich Anbieterkennung i.S.v. &sect;6 TDG)

            <br /><br />

            <table id="imprint_table">
                <tr>
                    <td valign='top'><b>Anschrift:</b></td>
                    <td>TeamSystems<br />
                        Roman Brunnemann<br />
                        Dachsteinweg 7<br />
                        12107 Berlin<br />

                    </td>
                </tr>

                <tr>
                    <td><b>E-Mail:</b></td>

                    <td><a href='mailto:info@teamsystems.de'>info@teamsystems.de</a></td>
                </tr>
                <tr>
                    <td><b>Internet:</b></td>
                    <td><a href='http://www.teamsystems.de'>www.teamsystems.de</a></td>
                </tr>
                <tr>

                    <td valign='top'><b>Bildernachweis:</b></td>
                    <td><a href="http://de.fotolia.com/id/24286504">© CYCLONEPROJECT - Fotolia.com</a><br/>
                    <a href="http://de.fotolia.com/id/47795312">© az - Fotolia.com</a><br/>
                    <a href="http://de.fotolia.com/id/47895272">© az - Fotolia.com</a><br/>
                    <a href="http://de.fotolia.com/id/45624603">© Jane - Fotolia.com</a><br/>
                    <a href="http://de.fotolia.com/id/14268547">© skvoor - Fotolia.com</a><br/>
                    <a href="http://de.fotolia.com/id/47250830">© Texelart - Fotolia.com</a><br/>
                    <a href="http://de.fotolia.com/id/43994583">© coramax - Fotolia.com</a><br/>
                    <a href="http://de.fotolia.com/id/44318381">© martanfoto - Fotolia.com</a><br/>
                    <a href="http://de.fotolia.com/id/23474034">© Anatoly Maslennikov - Fotolia.com</a><br/>
                    <a href="http://de.fotolia.com/id/54141522">© style-photography.de - Fotolia.com</a><br/>
                    <a href="http://de.fotolia.com/id/53951415">© Orlando Florin Rosu - Fotolia.com</a><br/>
                    <a href="http://de.fotolia.com/id/42266819">© fotomek - Fotolia.com</a><br/>
                    <a href="http://de.fotolia.com/id/30147214">© Texelart - Fotolia.com</a><br/>

                </td>
                </tr>
            </table>

        </div>
    )
}