import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  Routes,
  Route,
  Link as RouterLink,
} from "react-router-dom";
import Link from "@mui/material/Link";
import Home from "./components/Home";
import Imprint from "./components/Imprint";
import CssBaseline from "@mui/material/CssBaseline";


import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import HeaderBar from "./components/HeaderBar";
import TaC from "./components/TaC";
import Login from "./components/Login";

import Privacy from "./components/Privacy";
import PrivacyServiceProviderListing from "./components/PrivacyServiceProviderListing";
import Support from "./components/Support";
import Documentation from "./components/Documentation";
import SignUp from "./components/SignUp";
import SignUpEmailConfirm from "./components/SignUpEmailConfirm";
import config from "./config";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import SignUpStep2 from "./components/SignUpStep2";
import GlobalFeedbackSnackbar from "./components/GlobalFeedbackSnackbar";
import { SnackbarContainer } from "./contexts/SnackbarContext";
import { MatomoProvider, createInstance } from '@m4tt72/matomo-tracker-react'
import Blog from "./components/Blog";
import PasswordForgotten from "./components/PasswordForgotten";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);



const instance = createInstance({
  urlBase: 'https://analytics.teamsystems.de',
  siteId: process.env.NODE_ENV === "development" ? 3 : 1,
  //  trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
  // srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
  //disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: true, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: process.env.NODE_ENV === "development" ? false : true,
    setRequestMethod: 'POST'
  }
})

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link
        style={{ color: "rgba(0, 0, 0, 0.6)" }}
        href="https://www.teamsystems.de/"
      >
        TeamSystems
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const footerLinkStyle = {
  marginLeft: "30px",
  marginRight: "30px",
  color: "black",
  textDecorationColor: "black",
};

const theme = createTheme();

const client = new ApolloClient({
  uri: config.api.GRAPHQL_ENDPOINT,
  cache: new InMemoryCache(),
});




root.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>

          <SnackbarContainer>

            <CssBaseline />
            <ApolloProvider client={client}>

              <div id="content-wrap">
                <HeaderBar />

                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/impressum" element={<Imprint />} />
                  <Route path="/datenschutz" element={<Privacy />} />
                  <Route path="/datenschutz-anbieter-verzeichnis" element={<PrivacyServiceProviderListing />} />
                  <Route path="/agb" element={<TaC />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/dokumentation" element={<Documentation />} />
                  <Route path="/dokumentation/:contentSlug" element={<Documentation />} />
                  <Route path="/kostenlos-starten" element={<SignUp />} />
                  <Route path="/start-check-email" element={<SignUpEmailConfirm />} />
                  <Route path="/start-bestaetige-email/:confirmationToken" element={<SignUpStep2 />} />
                  <Route path="/passwort-vergessen" element={<PasswordForgotten />} />
                  <Route path="/blog" element={<Blog/>} />
                  <Route path="/blog/:contentSlug" element={<Blog/>} />
                  <Route path="/login" element={<Login/>} />
                </Routes>
                <GlobalFeedbackSnackbar />

              </div>
              <footer id="footer" style={{
                position: 'fixed', 
              paddingTop: '10px', 
              backgroundColor: 'white', 
              borderTopStyle: 'solid', borderTopColor: '#444444', 
              borderTopWidth: '1px', bottom: '0px'
              
            
               
              }}>

                <Typography
                  variant="subtitle1"
                  align="center"
                  color="text.secondary"
                  component="p"
                 
                > 
                  <RouterLink to="/datenschutz" style={footerLinkStyle}>
                    Datenschutz
                  </RouterLink>
                  <RouterLink to="/impressum" style={footerLinkStyle}>
                    Impressum
                  </RouterLink>
                  <RouterLink to="/agb" style={footerLinkStyle}>
                    AGB
                  </RouterLink>

                </Typography>
                <Copyright />
              </footer>

          </ApolloProvider>
        </SnackbarContainer>

      </ThemeProvider>
    </BrowserRouter>
  </MatomoProvider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
