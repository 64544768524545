const dev = {
  api: {
    GRAPHQL_ENDPOINT: "http://192.168.178.87:7000/graphql",
    // GRAPHQL_ENDPOINT: "http://g-api.ooo.de:7000/graphql",
  },
};

const prod = {
  api: {
    GRAPHQL_ENDPOINT: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  },
};

let baseUrl = window.location.protocol + "//" + window.location.host;

if (window.location.host.match(/ooo\.de/)) {
  baseUrl += "/app_dev.php";
}

const config = process.env.NODE_ENV === "development" ? dev : prod;

const content = {
  ...config,
  baseUrl,
}

export default content;

